<template>
  <FocusTrap>
    <div  id="mycard" class="card">

      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Sale's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>

          <div class="input-group-append position-static">
            <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
              <i class="icon-gear"></i>
            </button>
          </div>

        </div>
      </div>

      <div class="table-responsive">
        <table id="invoice_list_table"
             class="table table-columned table-hover"
             data-search="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-pagination="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-row-style="rowStyle"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="status" data-class="d-none">Status</th>
          <th data-field="id" data-class="d-none">Code</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
          <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
          <th data-field="ref_no" data-width="100" data-sortable="true">Inv No</th>
          <th data-field="type" data-width="100" data-formatter="transactionTypeFormatter" data-sortable="true">Type</th>
          <th data-field="ldgr.name" data-sortable="true">Buyer</th>
          <!--<th data-field="ref_no" data-width="100" data-sortable="true">Inv No</th>-->
          <!--<th data-field="ref_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Inv Date</th>-->

          <th data-field="gross_amount" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Taxable</th>
          <th data-field="tax_amount" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Tax</th>
          <th data-field="net_amount" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Amount</th>
          <th data-field="acc_code" data-sortable="true">Acc Code</th>
          <th data-field="remarks" data-sortable="true">Remarks</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
      </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-file-eye2" ></i>Preview</a>

        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify Invoice</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-cash4" style="color: darkgreen"></i>Receipt</a>
        <!--<a href="#" class="dropdown-item" > <i class="icon-pencil5" ></i>Modify Receipt</a>-->
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" > <i class="icon-database" ></i>Export to Accounts</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <FocusTrap>
      <vmodal name="invoice_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="80%" @before-open="beforeOpen" @before-close="beforeClose" >
        <InvoiceForm v-bind:myvoucher="invoice" v-on:sale-invoice-saved="on_invoice_saved" ></InvoiceForm>
      </vmodal>
    </FocusTrap>

    <!-- The Modal -->
    <div class="modal" id="invoiceModal" >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <InvoiceForm  v-if="invoice.id > 0" v-bind:myvoucher="invoice" v-on:sale-invoice-saved="on_invoice_saved" ></InvoiceForm>
          </div>

        </div>
      </div>
    </div>
    <!--/The Modal -->

    <div class="modal" id="receipt_modal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <ReceiptForm v-if="receipt.id > 0" v-bind:myreceipt="receipt" v-bind:invoice="invoice" v-on:receipt_updated="onReceiptUpdate" > </ReceiptForm>
          </div>

        </div>
      </div>
    </div>

    <div class="modal" id="previewmodal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <SaleInvoicePreview v-if="invoice.id > 1" v-bind:invoice="invoice" > </SaleInvoicePreview>
          </div>

        </div>
      </div>
    </div>

    <div class="modal" id="voucherpreview_modal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <VoucherPreviewForm v-if="voucher.id > 0" v-bind:myvoucher="voucher" > </VoucherPreviewForm>
          </div>

        </div>
      </div>
    </div>

    <FocusTrap>
      <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="250" @before-open="beforeOpen" >
      <form>
        <div class="card">
          <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
            <h3 class="card-title">Filter</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">From:</label>
              <div class="col-md-3">
                <input id="txtFromDate" ref="el_fromdate"   type="date" class="form-control"  v-model="from_date" :max="upto_date" />
              </div>

              <label class="col-md-3 col-form-label">Upto</label>
              <div class="col-md-3">
                <input ref="el_uptodate" type="date" class="form-control"  v-model="upto_date" :min="from_date" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">Sale Type:</label>
              <div class="col-md-9">
                <select id="cmbtypes" class="form-control" v-model="saletype" >
                  <option value="0" selected>All</option>
                  <option value="404">B2B-Local</option>
                  <option value="405">B2C-Local</option>
                  <option value="406">Export</option>
                  <option value="407">B2B-Others</option>
                  <option value="408">B2C-Others</option>

                  <option value="409">Exempted</option>
                </select>
              </div>
            </div>

            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>
    </FocusTrap>
  </div>
  </FocusTrap>
</template>

<script>
import InvoiceForm from '@/views/xpos/sale/SaleInvoiceForm.vue'
import ReceiptForm from '@/views/xpos/sale/SaleReceipt.vue'
import SaleInvoicePreview from '@/views/xpos/sale/SaleInvoicePreview.vue'
import VoucherPreviewForm from '@/views/account/voucher/VoucherPreviewForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'SaleInvoiceView',
  components: {
    InvoiceForm,
    ReceiptForm,
    SaleInvoicePreview,
    VoucherPreviewForm,
    FabButton
  },
  store,
  data () {
    return {
      mytable: {},
      receipt: JSON.parse('{"id":0,"type":0,"sale_code":0,"ibr":0,"status":0,"finyear":0,"inv_no":"","customer":{"id":0,"status":0,"type":0,"name":"","alias":"","opening_balance":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"receviedby":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"instrument_type":0,"instrument":"","rcpt_date":"0001-01-01","amount":0,"bank_com":0,"ex_rate":0,"dif_amt":0,"tds_amt":0,"remarks":"","acc_cd":0}'),
      voucher: JSON.parse('{"id":0,"type":0,"sale_code":0,"ibr":0,"status":0,"finyear":0,"inv_no":"","customer":{"id":0,"status":0,"type":0,"name":"","alias":"","opening_balance":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"receviedby":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"instrument_type":0,"instrument":"","rcpt_date":"0001-01-01","amount":0,"bank_com":0,"ex_rate":0,"dif_amt":0,"tds_amt":0,"remarks":"","acc_cd":0}'),
      invoice: JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":404,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
      series: 404,
      saletype: 0,
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD')
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#invoice_list_table');
    this.$data.mytable.bootstrapTable();
    $('#invoice_list_table').on('post-body.bs.table', function (e) {
      $('[data-toggle="popover"]').popover();
    });

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text()
        let status = parseInt($(row.children('*')[1]).text())
        let code = parseInt($(row.children('*')[2]).text())

        if ($(e.target).text() === 'Modify Invoice') {
          if (status === 0) {
            self.modifyDocument(id)
          }
        } else if ($(e.target).text() === 'Cancel') {
          if (status === 0) {
            self.removeDocument(id, status)
          }
        } else if ($(e.target).text() === 'Receipt') {
          self.saleReceipt(id, code, status)
        } else if ($(e.target).text() == 'Preview') {
          self.invoicePreview(id)
        } else if ($(e.target).text() == 'Export to Accounts') {
          // self.exportInvoice(parseInt($(row.children('*')[2]).text()));
          self.exportInvoice(id)
        }
      }
    })

    $('#txtsearch').keyup(function () {
      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
      $('#invoice_list_table>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide()
    })

    self.$data.from_date = moment().add(-7, 'd').format('YYYY-MM-DD')

    // self.loadData();
    self.$modal.show('filter_window')
  },
  methods: {
    showFilterWindow () {
      this.$modal.show('filter_window');

    },
    closeModal () {
      this.$modal.hide('invoice_window');
    },
    beforeOpen () {
      const self = this;
      this.$refs.el_fromdate.focus();
      $('#txtFromDate').focus();
    },
    beforeClose () {
      // this.$data.invoice = JSON.parse('{"id":0,"ver":0,"status":0,"is_cr":"N","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
    },
    showModal () {
      // this.$modal.show('invoice_window');
      $('#invoiceModal').modal('show');
    },
    on_invoice_saved () {
      this.$modal.hide('invoice_window');
    },
    blockModal () {
      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      })
    },
    onReceiptUpdate () {
      this.loadData()
      this.$data.receipt = JSON.parse('{"id":0,"type":0,"sale_code":0,"ibr":0,"status":0,"finyear":0,"inv_no":"","customer":{"id":0,"status":0,"type":0,"name":"","alias":"","opening_balance":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"receviedby":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"instrument_type":0,"instrument":"","rcpt_date":"0001-01-01","amount":0,"bank_com":0,"ex_rate":0,"dif_amt":0,"tds_amt":0,"remarks":"","acc_cd":0}')
      $('#receipt_modal').modal('hide')
    },
    voucherPreview (code) {
      const self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.voucher = JSON.parse('{"id":0,"com_id":"","type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"code":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}')
      fetch(`${process.env.VUE_APP_ROOT_API}/v1/accounts/voucher/code/${code}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok && !_.isNull(resp.data)) {
          self.$data.voucher = resp.data
          $('#voucherpreview_modal').modal('show');
        } else {
          swal({ title: 'Oh noes!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    invoicePreview (id) {
      const self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.invoice = {}
      fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/sale/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.invoice = resp.data;
          self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
          self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
          $('#previewmodal').modal('show')
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    exportInvoice (id) {
      const self = this

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.blockModal();
      fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/sale/export/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Good!', text: resp.msg, type: 'success' })
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(function () {
        $('#mycard').unblock()
      })
    },
    modifyDocument (id) {
      const self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.invoice = {}
      fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/sale/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.invoice = resp.data
          // self.$modal.show('invoice_window');
          $('#invoiceModal').modal('show')
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    saleReceipt (id, code, status) {
      const self = this

      if (status == 0) {
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$data.invoice = {}
        self.$data.receipt = JSON.parse('{"id":0,"type":0,"sale_code":0,"ibr":0,"status":0,"finyear":0,"inv_no":"","customer":{"id":0,"status":0,"type":0,"name":"","alias":"","opening_balance":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"receviedby":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"instrument_type":0,"instrument":"","rcpt_date":"0001-01-01","amount":0,"bank_com":0,"ex_rate":0,"dif_amt":0,"tds_amt":0,"remarks":"","acc_cd":0}')
        fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/sale/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.$data.invoice = resp.data
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD')
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD')
            self.$data.receipt.rcpt_date = moment().format('YYYY-MM-DD')
            self.$data.receipt.amount = self.$data.invoice.net_amount

            self.$data.receipt.id = 1
            $('#receipt_modal').modal('show')
          } else {
            swal({ title: 'Oh noes!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })
      } else if (status > 0) {
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        // alert(`${process.env.VUE_APP_ROOT_API}v1/tms/salereceipt/sale/${code}/`);

        self.$data.invoice = {}
        fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/sale/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.$data.invoice = resp.data
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD')
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD')

            self.$data.receipt = JSON.parse('{"id":0,"type":0,"sale_code":0,"ibr":0,"status":0,"finyear":0,"inv_no":"","customer":{"id":0,"status":0,"type":0,"name":"","alias":"","opening_balance":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"receviedby":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}},"instrument_type":0,"instrument":"","rcpt_date":"0001-01-01","amount":0,"bank_com":0,"ex_rate":0,"dif_amt":0,"tds_amt":0,"remarks":"","acc_cd":0}')
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/salereceipt/sale/${code}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
              if (resp.ok) {
                self.$data.receipt = resp.data
                self.$data.receipt.rcpt_date = moment(resp.data.rcpt_date).format('YYYY-MM-DD')

                $('#receipt_modal').modal('show')
              } else {
                swal({ title: 'Oops!', text: resp.msg, type: 'error' })
              }
            }).catch(function (err) {
              swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
            })
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })
      }
    },
    removeDocument (id) {
      const self = this

      self.$data.invoice.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.invoice)
      }

      swal({
        title: 'What is the reason for cancel this transaction?',
        input: 'text',
        inputPlaceholder: 'Enter Remarks here',
        showCancelButton: true,
        inputClass: 'form-control',
        inputValidator: function (value) {
          return !value && 'You need to write something!'
        }
      }).then(function (result) {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/sale/${id}/?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('receipt-window')
            if (resp.ok) {
              self.loadData()
              swal({
                title: 'Canceled!',
                text: 'Your request has been processed',
                type: 'success'
              })
            } else {
              swal({ title: 'Oops', text: resp.msg, type: 'info' })
            }
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
    loadData () {
      const self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('')

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      })

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      // alert(`${process.env.VUE_APP_ROOT_API}v1/xpos/sales?brn_id=${store.state.user.branch.id}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&saletype=${self.$data.saletype}`);
      fetch(`${process.env.VUE_APP_ROOT_API}v1/xpos/sales?brn_id=${store.state.user.branch.id}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&saletype=${self.$data.saletype}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
          self.$modal.hide('filter_window');
        } else {
          swal({ title: 'Oh noes!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock()
        // $(self.$data.mytable).bootstrapTable('resetView');
      })
    }
  }

}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
  .modal-content {
    padding: 0px;
  }
</style>
